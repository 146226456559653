import "./src/styles/global.css";
import React from "react"
import { ThemeProvider } from "./src/context/ThemeContext"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)

// Always force dark mode regardless of system preferences
if (typeof window !== 'undefined') {
  document.documentElement.classList.add("dark");
  localStorage.setItem("color-theme", "dark");
}
  