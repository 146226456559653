import React, { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext({ theme: 'dark', toggleTheme: () => {} });

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark'); // Always dark theme

  useEffect(() => {
    // Force dark theme immediately on client side
    if (typeof window !== 'undefined') {
      localStorage.setItem('color-theme', 'dark');
      document.documentElement.classList.add('dark');
    }
  }, []);

  useEffect(() => {
    document.documentElement.className = 'dark';
    localStorage.setItem('color-theme', 'dark');
  }, [theme]);

  // Maintain toggle function for interface consistency, but always return dark
  const toggleTheme = () => {
    setTheme('dark');
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
